import React from 'react';
import { graphql } from 'gatsby';
import Service from '../../components/Services/Service';
import DiamondIcon from '/src/assets/servicesIcons/diamond_white.svg';
import { Text } from '../../components/Common';
import { useBreadcrumbsNesteadSchema } from '../../hooks/useBreadcrumbsNesteadSchema';
import { SERVICES_ORIGINAL_BREADCRUMBS } from '../../constants';

const FrontEndPage = () => {
  const breadcrumbsElementsSchema = useBreadcrumbsNesteadSchema(
    'Front End Develop',
    SERVICES_ORIGINAL_BREADCRUMBS
  );

  return (
    <Service
      header="services page font end header"
      content="Front End Development Description"
      seo={{
        title: 'SEO title service front end',
        description: 'SEO description service front end',
        breadcrumbsElementsSchema,
      }}
      Icon={DiamondIcon}
    >
      <>
        <Text
          text="Front End Development Description1"
          variant="body1"
          component="p"
        />
        <br />
        <Text
          text="Front End Development Description2"
          variant="body1"
          component="p"
        />
        <br />
        <Text
          text="Front End Development Description3"
          variant="body1"
          component="p"
        />
        <br />
        <Text
          text="Front End Development Description4"
          variant="body1"
          component="p"
        />
      </>
    </Service>
  );
};

export default FrontEndPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
